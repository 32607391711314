// Модуль для кнопок из ckeditor.
//
// При создании кнопки пользователь выбирает цвет,
// при формировании кнопки цвет записывается в инлайн стиль.
//
// Hover нельзя записать в инлайн стиль, и нельзя
// добавить в css, т.к. кнопки на странице могут
// быть разного цвета. Из-за этого пришлось сделать
// js-ный вариант ховера.
//
// Модуль работает с элементами js-darken-hover,
// у которых должен быть атрибут data-color

import Color from 'color';

app.modules.darkenHover = (function (self) {
  function isProcessableElement(element) {
    return element.classList.contains('js-darken-hover');
  }

  function _onMouseOver(event) {
    const target = event.target;
    if (!isProcessableElement(target)) {
      return;
    }

    const color = new Color(target.style.backgroundColor);

    target.dataset.originalColor = color.string();
    target.style.backgroundColor = color.darken(0.2).string();
  }

  function _onMouseOut(event) {
    const target = event.target;
    if (!isProcessableElement(target)) {
      return;
    }

    target.style.backgroundColor = target.dataset.originalColor;
  }

  function _listener() {
    document.addEventListener('mouseover', _onMouseOver);
    document.addEventListener('mouseout', _onMouseOut);
  }

  self.load = _listener;
  return self;
})(app.modules.darkenHover || {});
