// Модуль формы отправки купона - приложение к app.modules.orderForm
import couponemail from './templates/coupon-email.handlebars';
import couponephone from './templates/coupon-phone.handlebars';

app.modules.couponForm = (function (self) {
  const SELECTORS = {
    codeInput: '.js-promo-code__input',
    copyBtn: '.js-promo-code__copy-btn',
  };

  const TEMPLATE = {
    email: couponemail,
    phone: couponephone,
  };

  function _switchType() {
    const $this = $(this),
      data = $this.data();

    if ($this.attr('set-send')) return;

    $('.js-cof-type-switcher')
      .removeClass('active js-promo-code__send')
      .attr('set-send', '');

    $this.addClass('active');
    app.config.isMobile &&
      $this.addClass('active js-promo-code__send').attr('set-send', true);

    app.config.orderFormSendType = data.type;
    $('.js-cof-send-type').html(TEMPLATE[data.type](data));
    $('.js-of-terms-submit').removeClass('hidden');
    $('.js-cof-phone').data({phoneMaskOptions: {onlyDefaultCode: true}});

    $doc.trigger('init:phoneMask');
  }

  function _prepareFields($form) {
    const $email = $form.find('.js-input-email-auth'),
      $phone = $form.find('.js-input-phone-auth'),
      $sendTypeField = $form.find('.js-cof-send-type-field');

    if ($email.length) {
      $sendTypeField.val('email');
      app.config.orderFormSendType = 'email';
    } else {
      if ($phone.length) {
        $sendTypeField.val('phone');
        app.config.orderFormSendType = 'phone';
      } else {
        $sendTypeField.val('');
      }
    }
  }

  function _showError($form, field, error) {
    $form.find('.js-cof-' + field).addClass(`error-on ${error}`);
    !app.config.isMobile && u_reRenderPopup();
    formSubmitter.enableButtons($form[0]);
  }

  function _validate($form) {
    const $email = $form
        .find('.js-cof-email:visible')
        .removeClass('error-on other exist empty'),
      $phone = $form
        .find('.js-cof-phone:visible')
        .removeClass('error-on other');
    let errorsCount = 0;

    if ($email && $email.length && !$email.val().length) {
      errorsCount++;
      _showError($form, 'email', 'empty');
    } else {
      if ($phone.length && !$phone.val().length) {
        errorsCount++;
        _showError($form, 'phone', 'other');
      }
    }

    return !errorsCount;
  }

  function _submit($form) {
    const email = $form.find('.js-cof-email:visible').val();
    !(app.config.currentUser.email || app.config.currentUser.phone) &&
      _prepareFields($form);

    if (app.config.isUserSigned && !_validate($form)) {
      return;
    }

    if (!app.config.isUserSigned || !email || !email.length) {
      app.modules.orderForm.submit($form);
      return;
    }

    app.modules.signForms
      .checkEmail(email)
      .fail(function () {
        _showError($form, 'email', 'other');
      })
      .done(function (userData) {
        if (userData.users.length) {
          _showError($form, 'email', 'exist');
        } else {
          app.modules.signForms
            .saveEmail(email)
            .fail(function () {
              _showError($form, 'email', 'other');
            })
            .done(function () {
              app.modules.orderForm.submit($form);
            });
        }
      });
  }

  function _triggerSubmitOrderForm() {
    $('.js-order-form').submit();
  }

  function _onCopyPromoCodeClick(event) {
    const copiedText = event.target.dataset && event.target.dataset.copiedText;
    const codeInput = document.querySelector(SELECTORS.codeInput);
    codeInput.select();
    document.execCommand('copy');
    event.target.innerText = copiedText;
    !app.config.currentUser && app.modules.userUtils.signIn({withSID: true});
    app.config.offerCard.enableLog &&
      fetch(app.config.offerCard.promoCodeLogUrl, {method: 'POST'});
  }

  function _listener() {
    $doc
      .on('click', '.js-cof-type-switcher', _switchType)
      .on('click', SELECTORS.copyBtn, _onCopyPromoCodeClick)
      .on('click', '.js-promo-code__send', _triggerSubmitOrderForm);
  }

  self.prepare = function ($form) {
    return _submit($form);
  };

  self.load = function () {
    _listener();
  };

  return self;
})(app.modules.couponForm || {});
