// модуль для отправки статистики в crm

app.modules.crmActivitiesStatistic = ((self = {}) => {
  function _handleSubmit(event) {
    const target = event.target;
    const activeTab = target.querySelector(
      '[aria-labelledby="ui-id-1"][aria-selected="true"]'
    );

    if (!_isValidTarget(target, activeTab)) return;

    _dispatchEvent(
      app.i18n[activeTab ? 'contexts_edit_header' : 'contexts_add_review']
    );
  }

  function _isValidTarget(target, activeTab) {
    return (
      target.closest('.js-reviews-form') ||
      (target.closest('.js-company-settings-form') && activeTab)
    );
  }

  function _dispatchEvent(context) {
    $doc.trigger('send:apressAnalytics', [
      {
        analyticsParams: {
          entity_type: 'Apress::Notifications::Delivery',
          entity_id: 1,
          user_id: app.config.currentUserId,
          company_id: app.config.company.id,
          event: 'activity_sk',
          context: context,
        },
      },
    ]);
  }

  function _listeners() {
    document.addEventListener('submit', _handleSubmit);
  }

  function _init() {
    if (!app.config.isCompanyPacketUnpaid) return;

    _listeners();
  }

  self.load = _init;
  return self;
})(app.modules.crmActivitiesStatistic);
