// Модуль попапа 'Мгновенный старт' на сайте компании
// В модуле реализован опросник

import {ls} from '@apress/apress-frontend-utils/utils/ls/ls.js';
import {fetchData} from '@apress/apress-frontend-utils/utils/fetch-data/fetch-data.js';
import {getNoticePopup} from './notice-popup.js';
import description from './templates/description.handlebars';
import questions from './templates/questions.handlebars';
import lastStep from './templates/last-step.handlebars';

app.modules.fastStartPopup = (function (self) {
  const _OPEN_POPAP_EVENT = 'fastStartPopup:open';
  const _config = app.config;
  const _keyClosedPopup = `closed_fast_start_${_config.currentCompanyId}`;
  const _companyFastStartUrl = _config.companyFastStartUrl;
  const _isCompanyInfoLocation =
    location.pathname.includes('admin/company_info');
  let _isSendData = false;
  let _popupContent;
  let _popupData;
  let _activeQuestionData;
  let _resultSurvey;

  function _init() {
    if (!_companyFastStartUrl) return;

    _checkСonditionAndCallPopup();
    _addListener();
  }

  function _checkСonditionAndCallPopup() {
    !ls.get(_keyClosedPopup) &&
      _isCompanyInfoLocation &&
      _config.companyRegistrantValidUser &&
      _getPopupData();
  }

  function _getPopupData(event) {
    fetchData({
      url: _companyFastStartUrl,
      onSuccess: (popupConfig) => {
        _preparePopupContent(popupConfig, event);
        _createAndRenderPopup();
        _dispatchEvent();
      },
      onFail: (error) => {
        console.error(error);
      },
    });
  }

  function _createAndRenderPopup() {
    app.modules.modalDialog.create({
      content: _popupContent,
      dialogClass: 'fast-start-popup',
      dialogContentClass: 'fast-start-popup__container',
      noCloseOverlay: true,
      close: _closePopup,
    });
  }

  function _dispatchEvent() {
    document.dispatchEvent(new Event('companyFastStart:showAddProduct'));
  }

  function _closePopup() {
    ls.set(_keyClosedPopup, true);

    setTimeout(function () {
      document.dispatchEvent(new Event('unshownHints:supportContacts'));
    }, 2000);

    _checkSendDataAndCallNoticePopup();
  }

  function _checkSendDataAndCallNoticePopup() {
    !_isSendData && getNoticePopup();
  }

  function _startSurvey() {
    _popupContent.innerHTML = questions(_popupData.questions);
    _validateForm(0);
  }

  function _checkLastSlide(index) {
    return _popupData.questions.length - 1 === index;
  }

  function _toggleSlide(target) {
    _offActiveSlide();

    let indexSlide = Number(_activeQuestionData.questionIndex);
    const typeButton = target.dataset.typeButton;

    if (typeButton === 'previews') {
      _checkLastSlide(indexSlide) && _toggleFinishButton(false);
      --indexSlide;
    } else {
      _checkLastSlide(indexSlide + 1) && _toggleFinishButton(true);
      ++indexSlide;
    }

    _validateForm(indexSlide);
    _togglePreviewsButton(indexSlide);
    _onActiveSlide(indexSlide);
    _toggleStepSlide(indexSlide);
    _toggleIndexButton(indexSlide);
  }

  function _toggleStepSlide(step) {
    document.querySelector('.js-step-questions').innerText = step + 1;
  }

  function _toggleIndexButton(id) {
    document.querySelector(
      '.js-toggle-button'
    ).id = `conversion-fast-start-popup-${id + 1}`;
  }

  function _offActiveSlide() {
    _activeQuestionData = _popupContent.querySelector(
      '.js-fieldset-question[data-active-slide=true]'
    ).dataset;
    _activeQuestionData.activeSlide = false;
  }

  function _onActiveSlide(index) {
    _popupContent.querySelector(
      `.js-fieldset-question[data-question-index="${index}"]`
    ).dataset.activeSlide = true;
  }

  function _togglePreviewsButton(index) {
    document.querySelector('.js-previews-question').disabled = !index;
  }

  function _toggleFinishButton(isLast) {
    const button = document.querySelector('.js-toggle-button');
    button.textContent = isLast
      ? app.i18n.companyCabinets.lastStep
      : app.i18n.companyCabinets.nextStep;
    button.classList.toggle('js-last-step', isLast);
    button.classList.toggle('js-next-question', !isLast);
  }

  function _finishSurvey() {
    _resultSurvey = {
      user_data: {
        name: _config.currentUser.name,
        phone: _config.currentUser.contacts || _config.currentUser.phone,
      },
      questions: _getFormValue(),
    };

    _popupContent.innerHTML = lastStep(_popupData.contacts);
  }

  function _prepareSurveyData() {
    const userData = {
      name: _getCleanValue(
        document.querySelector('.js-user-survey-name').value
      ),
      phone: _getCleanValue(
        document.querySelector('.js-user-survey-phone').value
      ),
    };

    for (let key in _resultSurvey['user_data']) {
      if (userData[key]) {
        _resultSurvey['user_data'][key] = userData[key];
      }
    }
  }

  function _submitSurvey() {
    _prepareSurveyData();
    _popupContent.innerHTML = description({
      ..._popupData.end,
      last: true,
    });

    fetchData({
      url: _config.companyRegistrantFormsUrl,
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(_resultSurvey),
      },
    }).then(() => (_isSendData = true));
  }

  function _getCleanValue(value) {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(/<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/gi);
    return value.trim().replace(regex, '');
  }

  function _getFormValue() {
    const formValue = [];
    document
      .querySelector('.js-form-popup-survey')
      .querySelectorAll('.js-fieldset-question')
      .forEach((fieldset, index) => {
        const fieldsetData = {
          question: '',
          answers: [],
        };

        fieldsetData.question =
          fieldset.querySelector('.js-question-title').innerHTML;
        fieldset.querySelectorAll('input').forEach((input) => {
          if (input.checked) {
            fieldsetData.answers.push(input.value);
          }
        });
        formValue[index] = fieldsetData;
      });
    return formValue;
  }

  function _validateForm(index) {
    const button = document.querySelector('.js-toggle-button');

    if (!_popupData.questions[index].required) {
      button.disabled = false;
      return;
    }

    const formValue = _getFormValue();
    button.disabled = !formValue[index].answers.length;
  }

  function _preparePopupContent(popupConfig, event) {
    _popupData = popupConfig;
    _popupContent = document.createElement('div');
    _popupContent.classList.add('fast-start-popup__content');
    _popupContent.innerHTML = _setFirstSlideonPopup(event);
  }

  function _setFirstSlideonPopup(event) {
    return event?.type === _OPEN_POPAP_EVENT
      ? questions(_popupData.questions)
      : description(_popupData.wellcome);
  }

  function _checkTargetAndActionPopup(evt) {
    const target = evt.target;

    if (target.classList.contains('js-start-survey')) {
      _startSurvey();
    }

    if (target.classList.contains('js-last-step')) {
      _finishSurvey();
    }

    if (target.classList.contains('js-next-question')) {
      _toggleSlide(target);
    }

    if (target.classList.contains('js-previews-question')) {
      _toggleSlide(target);
    }

    if (target.classList.contains('js-survey-fast-submit')) {
      _submitSurvey();
    }

    if (target.closest('.js-form-popup-survey')) {
      const activeQuestion = _popupContent.querySelector(
        '.js-fieldset-question[data-active-slide=true]'
      );

      activeQuestion && _validateForm(activeQuestion.dataset.questionIndex);
    }
  }

  function _addListener() {
    document.addEventListener(_OPEN_POPAP_EVENT, _getPopupData);
    document.addEventListener('click', _checkTargetAndActionPopup);
  }

  self.load = function () {
    _init();
  };

  return self;
})(app.modules.fastStartPopup || {});
