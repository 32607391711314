// Модуль для работы кнопки очистки формы поиска

app.modules.headerSearchForm = (function (self) {
  const _clearButton = document.querySelector('.js-clear-button');
  const _input = document.querySelector('.js-search-query');

  function _checkInputValue(evt) {
    evt.target.value.length ? _visibleClearButton() : _hideClearButton();
  }

  function _visibleClearButton() {
    _clearButton.classList.add('header-search-form__clear-button_visible');
  }

  function _hideClearButton() {
    _input.value = '';
    _clearButton.classList.remove('header-search-form__clear-button_visible');
  }

  function _addListeners() {
    _input.addEventListener('input', _checkInputValue);
    _input.addEventListener('focus', _checkInputValue);
    _clearButton.addEventListener('click', _hideClearButton);
  }

  self.load = _input && _addListeners;

  return self;
})(app.modules.headerSearchForm || {});
