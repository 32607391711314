// модуль для показа номеров телефонов на СК, которые добавляются через СКЕ

app.modules.showCompanyCustomPhone = ((self = {}) => {
  const PHONE_CLASSES = {
    phone: 'js-custom-block-phone',
    appeal: 'js-create-appeal-order',
    contactStat: 'js-company-contact-stat',
    analytics: 'js-send-phone-analytics',
  };

  const _handleClick = (event) => {
    const phone = event.target;

    if (!phone.classList.contains(PHONE_CLASSES.phone)) return;

    event.preventDefault();
    _showPhone(phone);
  };

  const _showPhone = (phone) => {
    phone.textContent = phone.dataset.visiblePhone;
    _preparePhoneClasses(phone, 'remove');
    document.dispatchEvent(
      new CustomEvent('send:externalAnalytics', {
        detail: app.config.analyticsCfg.showHiddenPhonesEvent,
      })
    );
  };

  const _preparePhoneData = (phone) => {
    _preparePhoneClasses(phone, 'add');
    const companyId = app.config.company.id;
    phone.dataset.targetActionType = 'phone_view';
    phone.dataset.actionType = 'phone_hits';
    phone.dataset.companyId = companyId;
    phone.dataset.company = companyId;
    phone.dataset.companyContactStatTypes = '["contacts_hits"]';
  };

  const _preparePhoneClasses = (phone, method) => {
    phone.classList[method](
      PHONE_CLASSES.appeal,
      method === 'add' && PHONE_CLASSES.contactStat,
      PHONE_CLASSES.analytics,
      PHONE_CLASSES.phone
    );
  };

  const _init = () => {
    const customPhones = document.querySelectorAll(`.${PHONE_CLASSES.phone}`);

    if (!customPhones) return;

    customPhones.forEach((phone) => _preparePhoneData(phone));
    _listener();
  };

  const _listener = () => {
    document.addEventListener('click', _handleClick);
  };

  self.load = _init;
  return self;
})(app.modules.showCompanyCustomPhone);
