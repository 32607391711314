// модуль для определения состояния админ меню при загрузке
import Cookies from 'js-cookie';

app.modules.adminMenuInitialState = (function (self) {
  const CLOSED_COOKIE = {
    name: 'closed_menu',
    value: 1,
    expires: 30,
  };
  const MAX_WIDTH_TO_SHOW_CLOSED_MENU = 740;
  const _adminMenu = document.querySelector('.js-company-admin-menu');

  const _setAttributesToShowAdminPanel = () => {
    const adminToolbar = document.querySelector('.js-company-admin-toolbar');

    _adminMenu.dataset.menuWide = 'opened';
    _adminMenu.dataset.menu = 'active';
    adminToolbar.dataset.menu = 'active';
  };

  const _handleClickToggleButton = () => {
    const {name, value, expires} = CLOSED_COOKIE;

    _toggleBodyStyle();
    _isActiveMenu()
      ? Cookies.set(name, value, {expires})
      : Cookies.remove(name);
  };

  const _toggleBodyStyle = () => {
    const body = document.querySelector('.js-company-admin-body');
    body?.classList.toggle('company-admin-body_menu-open');
  };

  const _isActiveMenu = () => {
    return _adminMenu.dataset.menu === 'active';
  };

  const _isNotShowWideMenu = () => {
    return (
      app.config.adminMenuInitialStateClosed ||
      Cookies.get(CLOSED_COOKIE.name) ||
      window.innerWidth <= MAX_WIDTH_TO_SHOW_CLOSED_MENU
    );
  };

  const _addListener = () => {
    document
      .querySelector('.js-company-admin-toggle-menu')
      ?.addEventListener('click', _handleClickToggleButton);
  };

  const _init = () => {
    if (!_adminMenu) return;

    _addListener();

    if (_isNotShowWideMenu()) return;

    _setAttributesToShowAdminPanel();
    _toggleBodyStyle();
    $doc.trigger('setStateAdminMenu');
  };

  self.load = _init;

  return self;
})(app.modules.adminMenuInitialState || {});
