// Модуль инициализации портальных стики тулбара и хедера

import {convertStringBooleanToRealBoolean} from 'src/assets/js/utils/convert-string-bool-to-real-bool/convert-string-bool-to-real-bool.js';

app.modules.toolbar = (function (self) {
  let _toolbarWasStuck, _scrollHeight;

  function _stickToolbar() {
    if (!_toolbarWasStuck) {
      document.dispatchEvent(new Event('portalToolbar:stuck'));
      _toolbarWasStuck = true;
    }
  }

  function _setDataSticky(elements, isNeedSticky) {
    elements.forEach((element) => {
      element.dataset.sticky = isNeedSticky;
    });
  }

  function _checkNeedStickyToolbar() {
    return (
      !document.querySelector('.js-main-toolbar') ||
      app.config.isPortalProductPage
    );
  }

  function _handleToolbarScroll() {
    if (_checkNeedStickyToolbar()) {
      window.removeEventListener('scroll', _setToolbarSticky);
      return;
    }

    _setToolbarSticky();
  }

  function _toggleSticky(handle) {
    _scrollHeight = handle;
    _setupToolbar(handle);
  }

  function _setToolbarSticky() {
    if (!Math.floor(window.scrollY)) {
      _toggleSticky(false);
      return;
    }

    if (!_scrollHeight) {
      _toggleSticky(true);
    }
  }

  function _toggleAccountMenu() {
    const accountToolbar = document.querySelector('.js-toolbar-profile');
    const accountToolbarMenu = document.querySelector(
      '.js-toolbar-account-settings'
    );

    accountToolbar.dataset.active = !convertStringBooleanToRealBoolean(
      accountToolbar.dataset.active
    );
    accountToolbarMenu.dataset.active = !convertStringBooleanToRealBoolean(
      accountToolbarMenu.dataset.active
    );
  }

  function _closeAccountSettings(event) {
    if (event.target.closest('.js-toolbar-account-settings')) return;

    _toggleAccountMenu();
  }

  function _openAccountSettings(event) {
    if (!event.target.closest('.js-toolbar-profile')) return;

    _toggleAccountMenu();
    event.stopPropagation();

    if (event.target.href === '#') {
      event.preventDefault();
    }
  }

  function _handleProfileMenuClick(event) {
    const accountToolbarMenu = document.querySelector(
      '.js-toolbar-account-settings'
    );

    if (!accountToolbarMenu) return;

    convertStringBooleanToRealBoolean(accountToolbarMenu.dataset.active)
      ? _closeAccountSettings(event)
      : _openAccountSettings(event);
  }

  function _setupToolbar(isChangeSticky) {
    _stickToolbar();
    _setDataSticky(
      document.querySelectorAll('.js-toolbar-sticky'),
      isChangeSticky
    );
  }

  function _addListener() {
    document.addEventListener('click', _handleProfileMenuClick);
    document.addEventListener('toolbar:load', _handleToolbarScroll);
    window.addEventListener('scroll', _handleToolbarScroll);
  }

  self.initFloatToolbar = function () {
    if (app.config.isMobile) return;
    _addListener();
  };

  return self;
})(app.modules.toolbar || {});
