/**
 * Запись статистики кликов по контактам СК
 *
 * Отправляется post запрос при клике на элемент с классом js-company-contact-stat.
 * URL берется из конфига app.config.company.contactClickStat.types.
 * Тип события берется из атрибута data-company-contact-stat-types (массив, типов может быть несколько)
 * Включение/отключение записи статистики - app.config.company.contactClickStat.enable
 */

app.modules.contactClickStat = ((self = {}) => {
  const CONFIG = app.config.company.contactClickStat;

  function _handleClick(event) {
    const target = event.target.closest('.js-company-contact-stat');
    if (!target) {
      return;
    }

    const types = JSON.parse(target.dataset.companyContactStatTypes);
    for (const type of types) {
      fetch(CONFIG.types[type], {method: 'POST'});
    }

    document.removeEventListener('click', _handleClick);
  }

  function _addListeners() {
    if (!CONFIG.enable) {
      return;
    }
    document.addEventListener('click', _handleClick);
  }

  self.load = _addListeners;
  return self;
})(app.modules.contactClickStat);
