// модуль для переключения видимости телефонов в шапке СК

app.modules.companyHeaderPhones = ((self = {}) => {
  const SHOW_PHONE_KEY = 'is_show_phones';
  const COMPANY_HEADER_PHONE = '.js-company-header-phone';

  function _handleClick(event) {
    if (!event.target.closest(COMPANY_HEADER_PHONE)) return;

    _renderPhones();
    sessionStorage.setItem(SHOW_PHONE_KEY, true);
  }

  function _renderPhones() {
    document.querySelectorAll(COMPANY_HEADER_PHONE).forEach((phone) => {
      phone.remove();
      document
        .querySelector('.js-company-phone')
        .append(_getPreparePhoneLink(phone));
    });
    document.dispatchEvent(
      new CustomEvent('send:externalAnalytics', {
        detail: app.config.analyticsCfg.showHiddenPhonesEvent,
      })
    );
  }

  function _preparePhoneLink(phone) {
    return app.config.isMobile ? phone : phone.replaceAll('-', ' - ');
  }

  function _getPreparePhoneLink(phone) {
    const phoneLink = document.createElement('a');
    const visiblePhone = phone.dataset.visiblePhone;
    phoneLink.classList.add('kontora-visible-phone');
    phoneLink.textContent = _preparePhoneLink(visiblePhone);
    phoneLink.href = `tel:${visiblePhone}`;

    return phoneLink;
  }

  function _listener() {
    document.addEventListener('click', _handleClick);
  }

  function _init() {
    if (!app.config.isMobile && sessionStorage.getItem(SHOW_PHONE_KEY)) {
      _renderPhones();
      return;
    }

    _listener();
  }

  self.load = _init;
  return self;
})(app.modules.companyHeaderPhones);
