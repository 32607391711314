import {fetchData} from '@apress/apress-frontend-utils/utils/fetch-data/fetch-data.js';
import description from './templates/notice.handlebars';

function _createAndRenderNoticePopup(popupData) {
  const _popupContent = document.createElement('div');
  _popupContent.classList.add('fast-start-popup__content');
  _popupContent.innerHTML = description({content: popupData});

  app.modules.modalDialog.create({
    content: _popupContent,
    dialogClass: 'fast-start-popup',
    dialogContentClass: 'fast-start-popup__container',
    overlayClass: 'fast-start-popup__overlay',
    noCloseOverlay: true,
  });
}

export function getNoticePopup() {
  fetchData({
    url: app.config.companyFastStartCloseUrl,
    onSuccess: (popupConfig) => {
      const _popupData = popupConfig.text_zone?.content;
      if (!_popupData) return;

      _createAndRenderNoticePopup(_popupData);
    },
  });
}
