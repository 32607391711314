var Handlebars = require("../../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input id=\"contact-field-email\" class=\"coupon-contact__field\" readonly type=\"text\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\">\r\n      <input class=\"js-cof-email\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\">\r\n      <a class=\"coupon-contact__link js-close-quick-popup\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"changeLink") : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias2(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"couponOrders.links.change",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":97},"end":{"line":12,"column":133}}}))
    + "</a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "      <input\r\n        id=\"contact-field-email\"\r\n        class=\"coupon-contact__field js-cof-email\"\r\n        type=\"text\"\r\n      >\r\n      <div\r\n        class=\"coupon-contact__error error-block js-of-error\"\r\n        data-exist=\""
    + alias2(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"couponOrders.errors.exist",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":56}}}))
    + "\"\r\n        data-other=\""
    + alias2(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"couponOrders.errors.other",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":56}}}))
    + "\"\r\n        data-empty=\""
    + alias2(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"couponOrders.errors.empty",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":56}}}))
    + "\"\r\n        data-type=\"email\"\r\n      ></div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"coupon-contact\">\r\n  <label\r\n    for=\"contact-field-email\"\r\n    class=\"coupon-contact__label\"\r\n  >\r\n    "
    + container.escapeExpression(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"couponOrders.labels.email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":40}}}))
    + "\r\n  </label>\r\n  <div class=\"coupon-contact__container\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "  </div>\r\n  <input type=\"hidden\" name=\"extra_params[coupon][send_to]\" value=\"email\">\r\n</div>\r\n";
},"useData":true});