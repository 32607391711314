// Модуль работы с картинками на пкт и попапах быстрого просмотра

app.modules.imagesHelper = ((self) => {
  const IMAGE_PREVIEWS_COUNT = 4;
  const _zoomImageBox = document.querySelector('.js-image-previews');

  function _changeImage(imagePreview) {
    const zoomImage = document.querySelector('.js-zoom-image');
    const sourceImageTitle = zoomImage.title;
    const zoomImageImg = zoomImage.querySelector('img');

    zoomImage.href = imagePreview.dataset.bigimage;
    zoomImage.title = sourceImageTitle;
    zoomImage.dataset.index = imagePreview.dataset.index;
    zoomImageImg.src = imagePreview.dataset.href;
    zoomImageImg.title = sourceImageTitle;
    zoomImageImg.alt = sourceImageTitle;

    [..._zoomImageBox.children].forEach((image) =>
      image.classList.remove('active')
    );
    imagePreview.closest('li').classList.add('active');

    window.ZoomImage && window.ZoomImage.reload();
  }

  function _init() {
    const imagePreviews = app.config.imagePreviews || IMAGE_PREVIEWS_COUNT;
    const imageCount = document.querySelectorAll('.js-thumb').length;

    imagePreviews < imageCount && _initializeContentSlider(imagePreviews);
    _initializeZoomImage();
  }

  function _initializeContentSlider(imagePreviews) {
    $('.js-image-previews').contentslider({
      classBlock: 'contentslider-preview contentslider-block',
      countView: imagePreviews,
      countSlide: imagePreviews,
      showScroll: false,
      animate: 300,
      wheelMouse: true,
      autoResize: true,
    });
  }

  function _initializeZoomImage() {
    window.ZoomImage &&
      window.ZoomImage.init({
        selector: '.js-zoom-preview',
        bigClass: 'big-block',
        zoomClass: 'mouse-trap',
        zoomSizeMode: 'lens',
      });
  }

  function _handleClick(event) {
    const clickedThumb = event.target.closest('.js-thumb');
    if (!clickedThumb) return;
    _changeImage(clickedThumb);
    event.preventDefault();
  }

  function _listener() {
    document.addEventListener('click', _handleClick);
  }

  self.load = () => {
    _init();
    _listener();
  };

  return self;
})(app.modules.imagesHelper || {});
