// Действия после создания СК (при первом открытии)

app.modules.companyCreationHandler = ((self = {}) => {
  const _initialEventId = 'Event_3211';

  function _removeSearchParams(url) {
    url.searchParams.delete('company');
    window.history.replaceState(null, null, url.href);
  }

  function _sendEvents() {
    const yaCounter = window[`yaCounter${app.config.defaultYmCounterId}`];

    yaCounter && yaCounter.reachGoal(_initialEventId);
    window.dataLayer && window.dataLayer.push({event: _initialEventId});
  }

  function _init() {
    const url = new URL(window.location.href);
    if (url.searchParams.get('company') !== 'new') {
      return;
    }

    _removeSearchParams(url);
    _sendEvents();
  }

  self.load = _init;
  return self;
})(app.modules.companyCreationHandler);
