var Handlebars = require("../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"fast-start-popup__bottom-container\">\n    <button\n      class=\"fast-start-popup__button aui-button aui-button_wide aui-button_color_blue js-start-survey\"\n      type=\"button\"\n    >\n      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"textButton") || (depth0 != null ? lookupProperty(depth0,"textButton") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"textButton","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":20}}}) : helper)))
    + "\n    </button>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"fast-start-popup__text-content\">\n  <h3 class=\"fast-start-popup__title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":47}}}) : helper)))
    + "</h3>\n  <p class=\"fast-start-popup__subtitle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"subTitle") || (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":52}}}) : helper)))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"last") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});