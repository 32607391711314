var Handlebars = require("../../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"coupon-contact\">\r\n  <label\r\n    for=\"contact-field-phone\"\r\n    class=\"coupon-contact__label\"\r\n  >\r\n    "
    + alias2(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"couponOrders.labels.mobile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":41}}}))
    + "\r\n  </label>\r\n  <div class=\"coupon-contact__container \">\r\n    <input\r\n      id=\"contact-field-phone\"\r\n      class=\"coupon-contact__field js-cof-phone js-of-customer_phone js-phone-mask\"\r\n      placeholder=\"\"\r\n      type=\"tel\"\r\n      value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"phone") : depth0), depth0))
    + "\"\r\n      name=\"order[customer_phone]\"\r\n    >\r\n    <div\r\n      class=\"coupon-contact__error error-block js-of-error\"\r\n      data-other=\""
    + alias2(__default(require("../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"couponOrders.errors.other",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":18},"end":{"line":19,"column":54}}}))
    + "\"\r\n      data-type=\"phone\"\r\n    ></div>\r\n  </div>\r\n  <input type=\"hidden\" name=\"extra_params[coupon][send_to]\" value=\"phone\">\r\n</div>\r\n";
},"useData":true});