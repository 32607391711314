// Модуль для вызова попапа регистранта в админке СК.
// js-company-promotion-button - кнопка вставляется из админки портала.

app.modules.promotionButtonTextZone = (function (self) {
  function _checkClassAndDispatchOpenPopupEvent(event) {
    _checkTargetClass(event) &&
      document.dispatchEvent(new Event('fastStartPopup:open'));
  }

  function _checkTargetClass(event) {
    return event.target.classList.contains('js-company-promotion-button');
  }

  function _addListener() {
    document.addEventListener('click', _checkClassAndDispatchOpenPopupEvent);
  }

  self.load = function () {
    _addListener();
  };

  return self;
})(app.modules.promotionButtonTextZone || {});
