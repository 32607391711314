// Модуль для отображения подскази с информацией о рекламодетеле на баннерах

import hint from './templates/barmen-advertiser-label-hint.handlebars';

app.modules.barmenAdvertiserLabel = ((self) => {
  const _labelClass = 'js-barmen-advertiser-label';

  const _showHint = (target) => {
    target.insertAdjacentHTML('afterend', hint(target.dataset));
  };

  const _hideHint = () => {
    document.querySelector('.js-barmen-advertiser-label__hint')?.remove();
  };

  const _stopRedirect = (event) => {
    if (!event.target.classList.contains(_labelClass)) {
      return;
    }

    event.preventDefault();
  };

  const _onMouseOut = (event) => {
    if (!event.target.classList.contains(_labelClass)) return;

    _hideHint();
  };

  const _onScroll = () => {
    app.config.isMobile && _hideHint();

    document.removeEventListener('scroll', _onScroll);
  };

  const _onTouchEnd = (event) => {
    _stopRedirect(event);
    const target = event.target;

    if (document.querySelector('.js-barmen-advertiser-label__hint')) {
      event.preventDefault();
      document.removeEventListener('scroll', _onScroll);
      _hideHint();
      return;
    }

    if (!target.classList.contains(_labelClass)) {
      return;
    }

    _showHint(target);

    document.addEventListener('scroll', _onScroll);
  };

  const _onMouseOver = (event) => {
    const target = event.target;

    if (!target.classList.contains(_labelClass)) {
      return;
    }

    _showHint(target);
  };

  const _listeners = () => {
    if (app.config.isMobile) {
      document.addEventListener('touchend', _onTouchEnd);
      return;
    }

    document.addEventListener('mouseover', _onMouseOver);
    document.addEventListener('mouseout', _onMouseOut);
    document.addEventListener('click', _stopRedirect);
  };

  self.load = _listeners;

  process.env.NODE_ENV === 'test' && (module.exports = self);

  return self;
})(app.modules.barmenAdvertiserLabel || {});
