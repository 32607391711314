var Handlebars = require("../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"fast-start-popup__text-content\">\n  <h3 class=\"fast-start-popup__title\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h3>\n  <p class=\"fast-start-popup__subtitle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subTitle") : depth0), depth0))
    + "</p>\n  <div class=\"fast-start-popup__user-contacts\">\n    <div class=\"aui-input\">\n      <input\n        class=\"aui-input__field aui-input__field_static-label js-user-survey-name\"\n        id=\"name\"\n        type=\"text\"\n        placeholder=\""
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias3,"companyCabinets.namePlaceholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":21},"end":{"line":10,"column":63}}}))
    + "\"\n        maxlength=\"50\"\n      >\n      <label\n        class=\"aui-input__label aui-input__label_static\"\n        for=\"name\"\n      >\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias3,"companyCabinets.enterName",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":44}}}))
    + "\n      </label>\n    </div>\n    <div class=\"aui-input\">\n      <input\n        class=\"aui-input__field aui-input__field_static-label js-user-survey-phone\"\n        id=\"phone\"\n        type=\"text\"\n        placeholder=\""
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias3,"companyCabinets.phonePlaceholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":21},"end":{"line":25,"column":64}}}))
    + "\"\n        maxlength=\"50\"\n      >\n      <label\n        class=\"aui-input__label aui-input__label_static\"\n        for=\"phone\"\n      >\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias3,"companyCabinets.enterPhone",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":45}}}))
    + "\n      </label>\n    </div>\n  </div>\n  <div class=\"fast-start-popup__bottom-container\">\n    <button\n      class=\"fast-start-popup__button aui-button aui-button_wide aui-button_color_blue js-survey-fast-submit\"\n      type=\"button\"\n    >\n      "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias3,"companyCabinets.sendSurvey",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":41,"column":43}}}))
    + "\n    </button>\n  </div>\n</div>\n";
},"useData":true});