// Управление размером iframe

// пример события из iframe:
// window.frameElement.dispatchEvent(new Event('resize:iframeContentResize', {bubbles: true}))

app.modules.iframeResizer = ((self = {}) => {
  function _resize(iframe) {
    iframe.style.height =
      iframe.contentWindow.document.body.scrollHeight + 'px';
  }

  function _handler(event) {
    const target = event.target;
    if (!target.classList.contains('js-responsive-iframe')) {
      return;
    }
    _resize(target);
  }

  function _listener() {
    document.addEventListener('load', _handler);
    document.addEventListener('resize:iframeContentResize', _handler);
  }

  self.load = _listener;
  return self;
})(app.modules.iframeResizer);
