/**
 * Функция для получения булевого значения из строковых значений "true/false"
 *
 * @param {string} stringBool - строковое значение "true/false"
 *
 * @returns {Boolean}
 */

export function convertStringBooleanToRealBoolean(stringBool) {
  return /true/.test(stringBool);
}
