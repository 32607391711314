var Handlebars = require("../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset\n      class=\"fast-start-popup__survey-question js-fieldset-question\"\n      data-question-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n      data-active-slide=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":7,"column":25},"end":{"line":7,"column":63}}})) != null ? stack1 : "")
    + "\"\n    >\n      <h3 class=\"fast-start-popup__title js-question-title\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"question") : depth0), depth0))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "      <ul class=\"fast-start-popup__answer-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":53,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "true";
},"4":function(container,depth0,helpers,partials,data) {
    return "false";
},"6":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"fast-start-popup__hint fast-start-popup__hint_optional\">\n        <p class=\"fast-start-popup__hint-content fast-start-popup__hint-content_optional\">\n          "
    + container.escapeExpression(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"companyCabinets.optionalQuestion",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":53}}}))
    + "\n        </p>\n      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"multiselectHint") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"fast-start-popup__hint\">\n          <p class=\"fast-start-popup__hint-content\">\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"multiselectHint") : depth0), depth0))
    + "\n          </p>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"fast-start-popup__answer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"multiselect") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":51,"column":17}}})) != null ? stack1 : "")
    + "        </li>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <input\n            class=\"aui-checkbox\"\n            type=\"checkbox\"\n            name=\"questions_"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n            value=\""
    + alias2(alias1(depth0, depth0))
    + "\"\n            id=\"questions-"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "_label-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n          >\n          <label class=\"aui-checkbox__label aui-checkbox__label_orange\" for=\"questions-"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "_label-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n            "
    + alias2(alias1(depth0, depth0))
    + "\n          </label>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <input\n            class=\"aui-radio__radio aui-radio__radio_color_blue\"\n            type=\"radio\"\n            name=\"questions_"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "\"\n            value=\""
    + alias2(alias1(depth0, depth0))
    + "\"\n            id=\"questions-"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "_label-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n          >\n          <label class=\"aui-radio__label\" for=\"questions-"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "_label-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n            "
    + alias2(alias1(depth0, depth0))
    + "\n          </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"fast-start-popup__survey js-form-popup-survey\" name=\"fastStartSurvey\">\n  <div class=\"fast-start-popup__text-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":56,"column":13}}})) != null ? stack1 : "")
    + "    <div class=\"fast-start-popup__bottom-container\">\n      <p class=\"fast-start-popup__step-survey\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"companyCabinets.step",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":59,"column":39}}}))
    + "\n        <span class=\"js-step-questions\">1</span>\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"companyCabinets.stepOf",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":61,"column":41}}}))
    + " "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"length") : depth0), depth0))
    + "\n      </p>\n      <div class=\"fast-start-popup__buttons-container\">\n        <button\n          class=\"fast-start-popup__button-back js-previews-question\"\n          data-type-button=\"previews\"\n          type=\"button\"\n          title=\""
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"companyCabinets.previewsQuestion",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":68,"column":17},"end":{"line":68,"column":60}}}))
    + "\"\n          disabled\n        ></button>\n        <button\n          class=\"aui-button aui-button_wide aui-button_color_blue js-next-question js-toggle-button\"\n          id=\"conversion-fast-start-popup-1\"\n          data-type-button=\"next\"\n          type=\"button\"\n          disabled\n        >\n          "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"companyCabinets.nextQuestion",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":78,"column":10},"end":{"line":78,"column":49}}}))
    + "\n        </button>\n      </div> \n    </div>\n  </div>\n</form>\n";
},"useData":true,"useDepths":true});