var Handlebars = require("../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"barmen-advertiser-label__hint-info\">ИНН: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"inn") || (depth0 != null ? lookupProperty(depth0,"inn") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"inn","hash":{},"data":data,"loc":{"start":{"line":4,"column":59},"end":{"line":4,"column":66}}}) : helper)))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"barmen-advertiser-label__hint-info\">ID: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ordId") || (depth0 != null ? lookupProperty(depth0,"ordId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ordId","hash":{},"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":67}}}) : helper)))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"barmen-advertiser-label__hint js-barmen-advertiser-label__hint\">\n  <div class=\"barmen-advertiser-label__hint-payer\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"payer") || (depth0 != null ? lookupProperty(depth0,"payer") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"payer","hash":{},"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":60}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inn") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ordId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});